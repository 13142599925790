import { PoiOpenStatus } from '@m2/blueprint-locuslabs-sdk';
import { IPoi } from '../types/IPoi';

/**
 * We need to split into two arrays as open POIs need to show first
 * but should also be alphabetically sorted
 */
export function getOrderedPoiItems(pois: IPoi[]) {
	const openPois = pois.filter((poi) => poi.openStatus === PoiOpenStatus.Open);
	const unknownPois = pois.filter(
		(poi) => poi.openStatus === PoiOpenStatus.Unknown,
	);
	const closedPois = pois.filter(
		(poi) => poi.openStatus === PoiOpenStatus.Closed,
	);

	// Sort each array alphabetically and then combine each array
	return [
		...sortAlphabetically(openPois),
		...sortAlphabetically(unknownPois),
		...sortAlphabetically(closedPois),
	];
}

const sortAlphabetically = (pois: IPoi[]) =>
	pois.sort((a, b) => a.name.localeCompare(b.name));
