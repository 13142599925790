import noResultsImage from '../../assets/no-unified-search-results.svg?reference';

export type UnifiedSearchEmptyStateViewModel = ReturnType<
	typeof createUnifiedSearchEmptyStateViewModel
>;

export const createUnifiedSearchEmptyStateViewModel = () => ({
	body: 'Type any keywords to search for flights, facilities or information',
	imageSource: noResultsImage,
});
