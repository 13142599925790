import { TerminalList } from '../types/Terminals';
import { IPoi } from '../types/IPoi';
import { itemsCategoryFilter } from './itemsCategoryFilter';
import { itemsQueryFilter } from './itemsQueryFilter';
import { itemsTerminalFilter } from './itemsTerminalFilter';
import { itemsTagFilter } from './itemsTagFilter';
import { IItemFilterOptions } from '../types/IItemFilterOptions';

export function getFilteredPoiItems(
	pois: IPoi[],
	{ category, terminal, tag, userQuery }: IItemFilterOptions,
) {
	return pois
		.filter((poi) => TerminalList.includes(poi.terminal || ''))
		.filter((poi) => poi.nearbyLandmark?.includes('Gate'))
		.filter((poi) => itemsCategoryFilter(category, poi))
		.filter((poi) => itemsTagFilter(tag, poi))
		.filter((poi) => itemsTerminalFilter(terminal, poi))
		.filter((poi) => itemsQueryFilter(userQuery, poi));
}
