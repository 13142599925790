import noResultsImage from '../../assets/no-unified-search-results.svg?reference';

export type UnifiedSearchNothingFoundViewModel = ReturnType<
	typeof createUnifiedSearchNothingFoundViewModel
>;

export const createUnifiedSearchNothingFoundViewModel = () => ({
	title: 'Nothing found',
	body: 'Please check provided details and try again',
	imageSource: noResultsImage,
	imageAlt: 'No flights, facilities and information found',
});
