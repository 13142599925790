import { ApiClient } from './ApiClient';
import {
	CheckpointRecommendationService,
	FlightListService,
	ShuttleStopsService,
} from './services';
import { CheckpointsService } from './services/CheckpointsService';
import { ParkingAvailabilityService } from './services/ParkingAvailabilityService';
import { ParkingRatesService } from './services/ParkingRatesService';
import { WeatherService } from './services/WeatherService';
import { ShuttleVehiclesService } from './services/ShuttleVehiclesService';
import { ShuttleStopEtasService } from './services/ShuttleStopEtasService';
import { ShuttleRoutesService } from './services/ShuttleRoutesService';
import { BusStopsSchedulesService } from './services/BusStopsSchedules';

export class Skydive {
	static API_VERSION = '160';

	static API_KEY = '87856E0636AA4BF282150FCBE1AD63DE';

	protected client: ApiClient;

	constructor(baseUrl: string) {
		this.client = new ApiClient({
			baseURL: baseUrl,
			method: 'POST',
			headers: {
				'Api-Version': Skydive.API_VERSION,
				'Api-Key': Skydive.API_KEY,
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		});
	}

	public flights() {
		return new FlightListService(this.client);
	}

	public weather() {
		return new WeatherService(this.client);
	}

	public checkpoints() {
		return new CheckpointsService(this.client);
	}

	public checkpointRecommendation() {
		return new CheckpointRecommendationService(this.client);
	}

	public shuttleStops() {
		return new ShuttleStopsService(this.client);
	}

	public shuttleVehicles() {
		return new ShuttleVehiclesService(this.client);
	}

	public shuttleStopEtas() {
		return new ShuttleStopEtasService(this.client);
	}

	public shuttleRoutes() {
		return new ShuttleRoutesService(this.client);
	}

	public busStopsSchedules() {
		return new BusStopsSchedulesService(this.client);
	}

	public parkingAvailability() {
		return new ParkingAvailabilityService(this.client);
	}

	public parkingRates() {
		return new ParkingRatesService(this.client);
	}
}
