import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import { UnifiedSearchStore } from '../domain/stores/createUnifiedSearchStore';
import { TypeFilterField } from '../domain/types/TypeFilterField';
import { createUnifiedSearchNothingFoundViewModel } from './UnifiedSearchNothingFoundViewModel';
import { createSearchResultItemViewModel } from './SearchResultItemViewModel';
import { PoiStore } from '../../shop-dine-relax/domain/stores/createPoiStore';
import { MINIMAL_CHARS_FOR_RESULTS } from '../constants';
import { createUnifiedSearchEmptyStateViewModel } from './UnifiedSearchEmptyStateViewModel';

export type SearchOverlayViewModel = ReturnType<
	typeof createSearchOverlayViewModel
>;

export interface IUnifiedSearchOverlayViewModelProps {
	poiStore: PoiStore;
	unifiedSearchStore: UnifiedSearchStore;
	pathToPageIdMap: IPathToPageIdMap;
}

export const createSearchOverlayViewModel = ({
	poiStore,
	unifiedSearchStore,
	pathToPageIdMap,
}: IUnifiedSearchOverlayViewModelProps) => {
	const resultItemViewModels = unifiedSearchStore?.results
		.map((item) =>
			createSearchResultItemViewModel({
				item,
				unifiedSearchStore,
				pathToPageIdMap,
			}),
		)
		.filter(
			({ url, contentType }) =>
				!(!url && contentType === TypeFilterField.Content),
		);
	const hasMinimalCharsForResult =
		unifiedSearchStore.userQuery.length >= MINIMAL_CHARS_FOR_RESULTS;

	const isSearchingWithoutResults =
		hasMinimalCharsForResult && resultItemViewModels.length === 0;

	const isNotSearchingWithoutRecentResults =
		!hasMinimalCharsForResult && unifiedSearchStore.recentSearches.length === 0;

	const nothingFoundViewModel = isSearchingWithoutResults
		? createUnifiedSearchNothingFoundViewModel()
		: null;

	const emptyStateViewModel = isNotSearchingWithoutRecentResults
		? createUnifiedSearchEmptyStateViewModel()
		: null;

	const recentSearchViewModels = unifiedSearchStore?.recentSearches.map(
		(item) =>
			createSearchResultItemViewModel({
				item,
				unifiedSearchStore,
				pathToPageIdMap,
			}),
	);

	const showRecentSearches =
		resultItemViewModels.length === 0 &&
		unifiedSearchStore.userQuery.length < MINIMAL_CHARS_FOR_RESULTS;

	return {
		onMount() {
			unifiedSearchStore.init();

			if (!poiStore.hasFinishedInitialLoad && !poiStore.isLoading) {
				poiStore.load();
			}
		},
		onUnmount() {
			poiStore.reset();
		},
		onSearch(value: string) {
			unifiedSearchStore?.setSuggestionInput(value);
		},
		handleClearInput() {
			unifiedSearchStore?.reset();
		},
		get hasUserQuery() {
			return unifiedSearchStore?.userQuery.length > 0;
		},
		get userQuery() {
			return unifiedSearchStore?.userQuery;
		},
		resultItemViewModels,
		recentSearchViewModels,
		placeholderText: 'e.g. aa2853, parking',
		get isUnifiedSearchActive() {
			return unifiedSearchStore?.isActive;
		},
		searchResultsCountText: `Search results (${resultItemViewModels.length})`,
		recentSearchesText: 'Recent searches',
		showRecentSearches,
		get filterButtons() {
			const { selectedFilters } = unifiedSearchStore;

			return [
				{
					label: 'Flights',
					type: TypeFilterField.Flights,
					isActive: selectedFilters.includes(TypeFilterField.Flights),
				},
				{
					label: 'Facilities',
					type: TypeFilterField.Pois,
					isActive: selectedFilters.includes(TypeFilterField.Pois),
				},
				{
					label: 'Information',
					type: TypeFilterField.Content,
					isActive: selectedFilters.includes(TypeFilterField.Content),
				},
			];
		},
		onSelectFilter(filter: TypeFilterField) {
			const filterIndex = unifiedSearchStore?.selectedFilters.indexOf(filter);
			const selectedFilters = [...unifiedSearchStore?.selectedFilters];

			if (filterIndex > -1) {
				selectedFilters.splice(filterIndex, 1);
			} else {
				selectedFilters.push(filter);
			}

			unifiedSearchStore.setSelectedFilters(selectedFilters);
		},
		nothingFoundViewModel,
		emptyStateViewModel,
		showEndOfResults: !!(!showRecentSearches && resultItemViewModels?.length),
		endOfResultText: 'End of Results',
	};
};
