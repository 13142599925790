import React, {
	AnchorHTMLAttributes,
	FunctionComponent,
	KeyboardEvent,
	MouseEvent,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {
	InfoIcon,
	ITheme,
	LinkText,
	LocationIcon,
	PlaneDepartureIcon,
} from '@m2/blueprint-ui';
import Link from 'next/link';
import { SearchResultItemViewModel } from './SearchResultItemViewModel';
import { TypeFilterField } from '../domain/types/TypeFilterField';
import { Url } from '../../navigation';

export interface ISearchResultItemProps {
	viewModel: SearchResultItemViewModel;
	isRecentSearchesMode?: boolean;
}

const PossibleLink: FunctionComponent<
	{ url?: Url } & AnchorHTMLAttributes<HTMLAnchorElement | HTMLDivElement>
> = ({ url, children, onKeyDown, onClick }) => {
	if (url) {
		return (
			<Link href={url}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
				<a tabIndex={0} onKeyDown={onKeyDown} role="button" onClick={onClick}>
					{children}
				</a>
			</Link>
		);
	}
	return (
		<div tabIndex={0} onKeyDown={onKeyDown} role="button" onClick={onClick}>
			{children}
		</div>
	);
};

export const SearchResultItem = ({
	isRecentSearchesMode,
	viewModel: {
		item: { title, description },
		onClick,
		contentType,
		url,
	},
}: ISearchResultItemProps) => {
	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			onClick();
		}
	};

	const handleOnClick = (event: MouseEvent) => {
		// Don't bubble up to other click events handlers, this will prevent the search modal from closing
		event.stopPropagation();
		onClick();
	};

	return (
		<StyledResultItem>
			<PossibleLink url={url} onClick={handleOnClick} onKeyDown={handleKeyDown}>
				<StyledResultItemContent>
					<StyledItemIcon>
						{contentType === TypeFilterField.Flights && <PlaneDepartureIcon />}
						{contentType === TypeFilterField.Pois && <LocationIcon />}
						{contentType === TypeFilterField.Content && <InfoIcon />}
					</StyledItemIcon>
					<StyledContent isRecentSearchesMode={isRecentSearchesMode}>
						<LinkText>
							<StyledItemTitle
								dangerouslySetInnerHTML={{
									// eslint-disable-next-line @typescript-eslint/naming-convention
									__html: title,
								}}
							/>
							{description && (
								<StyledItemDescription
									dangerouslySetInnerHTML={{
										// eslint-disable-next-line @typescript-eslint/naming-convention
										__html: description,
									}}
								/>
							)}
						</LinkText>
					</StyledContent>
				</StyledResultItemContent>
			</PossibleLink>
		</StyledResultItem>
	);
};

const StyledResultItem = styled.div`
	a:hover {
		text-decoration: none;
	}
`;

const StyledContent = styled.div<{ isRecentSearchesMode?: boolean }, ITheme>`
	& em {
		font-style: normal;
		${({ isRecentSearchesMode }) =>
			!isRecentSearchesMode &&
			css`
				font-weight: bold;
				color: #1a1a1a;
			`};
	}
`;

const StyledItemDescription = styled.p`
	font-size: 14px;
	font-weight: 400;
	color: #a7a7a7;
	margin-bottom: 0;
`;

const StyledItemTitle = styled.h3`
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 6px;
	color: #1a1a1a;
`;

const StyledResultItemContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	padding: 24px 0;
	border-bottom: 1px solid #e6e6e6;
`;

const StyledItemIcon = styled.div`
	margin-right: 16px;
	line-height: 0;

	g {
		fill: #a7a7a7;
	}
`;
