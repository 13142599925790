import { makeAutoObservable } from 'mobx';
import { ContentSearchStore } from '../../../content-search/domain/stores/createContentSearchStore';
import { IContentSearchResult } from '../../../content-search/domain/types/IContentSearchResult';
import { IFlightSearchSuggestion } from '../../../flights/search/domain/types/IFlightSearchSuggestion';
import { SearchFilters } from '../../../flights/search/domain/types/SearchFilters';
import { FlightSuggestionStore } from '../../../flights/shared/domain/stores/createFlightSuggestionStore';
import { PoiSearchStore } from '../../../shop-dine-relax/domain/stores/createPoiSearchStore';
import { IPoi } from '../../../shop-dine-relax/domain/types/IPoi';
import { IUnifiedSearchResult } from '../models/IUnifiedSearchResult';
import { PoiFilterField } from '../types/PoiFilterField';
import { AutoSuggestFilterField } from '../../../flights/search/domain/types/AutoSuggestFilterField';
import { TypeFilterField } from '../types/TypeFilterField';
import { getFilteredResults } from '../use-cases/getFilteredResults';
import { PersistRecentSearches } from '../use-cases/persistRecentSearches';
import { MINIMAL_CHARS_FOR_RESULTS } from '../../constants';

export type UnifiedSearchStore = ReturnType<typeof createUnifiedSearchStore>;

export interface IUnifiedSearchStoreProps {
	flightSuggestionStore: FlightSuggestionStore;
	poiSearchStore: PoiSearchStore;
	contentSearchStore: ContentSearchStore;
	persistRecentSearches: PersistRecentSearches;
}

export function createUnifiedSearchStore({
	flightSuggestionStore,
	poiSearchStore,
	contentSearchStore,
	persistRecentSearches,
}: IUnifiedSearchStoreProps) {
	return makeAutoObservable({
		userQuery: '',
		async init() {
			if (!flightSuggestionStore.finishedInitialLoad) {
				await flightSuggestionStore.load();
			}

			if (this.recentSearches.length === 0) {
				const possibleRecentSearches =
					persistRecentSearches.getRecentSearchesFromLocalStorage();

				this.setRecentSearches(
					possibleRecentSearches.filter(
						(entry: unknown) =>
							typeof entry === 'object' &&
							entry !== null &&
							'title' in entry &&
							'key' in entry &&
							'filter' in entry &&
							'id' in entry,
					) as IUnifiedSearchResult[],
				);
			}
		},
		reset() {
			this.setSuggestionInput('');
			this.setSelectedFilters([]);
			poiSearchStore.clearSearchInput();
			contentSearchStore.reset();
		},
		get results(): IUnifiedSearchResult[] {
			if (
				this.userQuery === '' ||
				this.userQuery.length < MINIMAL_CHARS_FOR_RESULTS
			) {
				return [];
			}

			const filteredResults = getFilteredResults(
				flightSuggestionStore?.suggestionList,
				poiSearchStore?.pois,
				poiSearchStore.tags,
				poiSearchStore?.terminals,
				contentSearchStore.content,
				this.selectedFilters,
			);

			const mappedFlights =
				filteredResults.flights.map(
					mapFlightsSuggestionToUnifiedSearchResult,
				) ?? [];
			const mappedPois =
				filteredResults.pois.map(mapPoisToUnifiedSearchResult) ?? [];
			const mappedPoiTags =
				filteredResults.poiTags.map(mapPoiTagsToUnifiedSearchResult) ?? [];
			const mappedPoiTerminals =
				filteredResults.poiTerminals.map(mapTerminalsToUnifiedSearchResult) ??
				[];
			const mappedContentResults =
				filteredResults.content.map((content) =>
					mapContentToUnifiedSearchResult(content),
				) ?? [];

			return [
				...mappedFlights,
				...mappedPois,
				...mappedPoiTags,
				...mappedPoiTerminals,
				...mappedContentResults,
			];
		},
		isActive: false,
		setIsActive(isActive: boolean) {
			this.isActive = isActive;
		},
		setSuggestionInput(query: string) {
			this.setUserQuery(query);
			poiSearchStore.setUserQuery(query);
			flightSuggestionStore.setSuggestionInput(query);
			contentSearchStore.setUserQuery(query);
		},
		applySearch(searchFilter: SearchFilters) {
			flightSuggestionStore.applySearch(searchFilter);
		},
		setUserQuery(query: string) {
			this.userQuery = query;
		},
		selectedFilters: [] as TypeFilterField[],
		setSelectedFilters(filters: TypeFilterField[]) {
			this.selectedFilters = filters;
		},
		recentSearches: [] as IUnifiedSearchResult[],
		setRecentSearchResult(result: IUnifiedSearchResult) {
			if (!this.recentSearches.find(({ key }) => result.key === key)) {
				this.setRecentSearches([result, ...this.recentSearches.slice(0, 4)]);
				this.saveRecentSearchesToLocalStorage();
			}
		},
		setRecentSearches(recentSearches: IUnifiedSearchResult[]) {
			this.recentSearches = recentSearches;
		},
		saveRecentSearchesToLocalStorage() {
			persistRecentSearches.saveRecentSearchesToLocalStorage(
				this.recentSearches,
			);
		},
	});
}

function mapFlightsSuggestionToUnifiedSearchResult(
	flight: IFlightSearchSuggestion,
): IUnifiedSearchResult {
	return {
		key: flight.key ?? '',
		id: '',
		description: getFlightDescription(flight.filter) ?? flight.hint,
		filter: flight.filter,
		title: flight.stringValue,
	};
}

function getFlightDescription(filter: AutoSuggestFilterField) {
	switch (filter) {
		case AutoSuggestFilterField.AirportCity:
		case AutoSuggestFilterField.AirportName:
			return 'Destination';
		default:
			return null;
	}
}

function mapPoisToUnifiedSearchResult(poi: IPoi): IUnifiedSearchResult {
	return {
		key: poi.poiId,
		id: poi.poiId,
		filter: PoiFilterField.Pois,
		title: poi.name,
		description:
			poi.terminal && poi.nearbyLandmark
				? `${poi.terminal} - ${poi.nearbyLandmark}`
				: '',
	};
}

function mapPoiTagsToUnifiedSearchResult(tag: string): IUnifiedSearchResult {
	return {
		key: tag,
		id: '',
		filter: PoiFilterField.PoiTags,
		title: tag,
	};
}

function mapTerminalsToUnifiedSearchResult(
	terminal: string,
): IUnifiedSearchResult {
	return {
		key: terminal,
		id: '',
		filter: PoiFilterField.PoiTerminals,
		title: terminal,
	};
}

function mapContentToUnifiedSearchResult(
	content: IContentSearchResult,
): IUnifiedSearchResult {
	return {
		key: content.id,
		id: content.pageId,
		filter: 'content',
		title: content.snippet.title.value,
		description: content.snippet.bodyString?.value,
	};
}
