import { AxiosRequestConfig } from 'axios';
import { AbstractService } from './AbstractService';
import { ISkydiveShuttleStopEtasResponse } from '../types/ISkydiveShuttleStopEtasResponse';

export class ShuttleStopEtasService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = '/bus-stops/etas';

	public getShuttleStopEtas(
		requestConfig: AxiosRequestConfig = {},
	): Promise<ISkydiveShuttleStopEtasResponse> {
		const uri = ShuttleStopEtasService.URI_PATH;

		return this.client.request(uri, {
			method: 'GET',
			...requestConfig,
		});
	}
}
