import { AxiosRequestConfig } from 'axios';
import { AbstractService } from './AbstractService';
import { ISkydiveShuttleVehiclesResponse } from '../types/ISkydiveShuttleVehiclesResponse';

export class ShuttleVehiclesService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = '/bus-stops/vehicles';

	public getShuttleVehicles(
		requestConfig: AxiosRequestConfig = {
			method: 'GET',
		},
	): Promise<ISkydiveShuttleVehiclesResponse> {
		const uri = ShuttleVehiclesService.URI_PATH;

		return this.client.request(uri, requestConfig);
	}
}
