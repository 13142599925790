import styled from '@emotion/styled';
import {
	CancelIcon,
	Responsive,
	SearchInputButtonWrap,
	TextField,
} from '@m2/blueprint-ui';
import { DialogOverlay } from '@reach/dialog';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { SearchOverlayViewModel } from './SearchOverlayViewModel';
import { SearchResultItem } from './SearchResultItem';
import { UnifiedSearchEmptyState } from './UnifiedSearchEmptyState';
import { UnifiedSearchNothingFound } from './UnifiedSearchNothingFound';

interface ISearchOverlayProps {
	viewModel: SearchOverlayViewModel;
}

export const SearchOverlay = observer(({ viewModel }: ISearchOverlayProps) => {
	useEffect(() => {
		viewModel.onMount();

		return () => viewModel.onUnmount();
	}, []);

	const showEndOfResults = !!(
		!viewModel.showRecentSearches && viewModel.resultItemViewModels?.length
	);

	return (
		<StyledDialogOverlay isOpen={viewModel.isUnifiedSearchActive}>
			<StyledOverlayWrapper>
				<StyledContent>
					<StyledSearchInputButtonWrap
						onClickSearch={() => {
							viewModel.onSearch(viewModel.userQuery);
						}}
					>
						<TextField
							autoFocus
							onChange={(ev) => {
								viewModel.onSearch(ev.currentTarget.value);
							}}
							value={viewModel.userQuery ?? ''}
							placeholder={viewModel.placeholderText}
							name="unified-search"
							ariaLabel={viewModel.placeholderText}
						/>
						{viewModel.hasUserQuery ? (
							<StyledClearInput onClick={viewModel.handleClearInput}>
								<StyledCancelIcon color="red" />
							</StyledClearInput>
						) : null}
					</StyledSearchInputButtonWrap>

					{viewModel.emptyStateViewModel ? (
						<UnifiedSearchEmptyState
							viewModel={viewModel.emptyStateViewModel}
						/>
					) : (
						<>
							<StyledTopWrapper>
								<StyledSearchTitle>
									{viewModel.showRecentSearches
										? viewModel.recentSearchesText
										: viewModel.searchResultsCountText}
								</StyledSearchTitle>

								<StyledFiltersWrapper>
									{viewModel.filterButtons.map((filter) => (
										<StyledFilterButton
											key={filter.type}
											active={filter.isActive}
											onClick={() => viewModel.onSelectFilter(filter.type)}
										>
											{filter.label}
										</StyledFilterButton>
									))}
								</StyledFiltersWrapper>
							</StyledTopWrapper>

							<StyledListItems>
								{viewModel.showRecentSearches &&
									viewModel.recentSearchViewModels?.map((recentViewModel) => (
										<SearchResultItem
											isRecentSearchesMode
											key={`recent-${recentViewModel.item.key}`}
											viewModel={recentViewModel}
										/>
									))}

								{!viewModel.showRecentSearches &&
									viewModel.resultItemViewModels?.map((resultViewModel) => (
										<SearchResultItem
											key={`result-${resultViewModel.item.key}`}
											viewModel={resultViewModel}
										/>
									))}

								{showEndOfResults && (
									<StyledEndOfResults>
										{viewModel.endOfResultText}
									</StyledEndOfResults>
								)}

								{viewModel.nothingFoundViewModel ? (
									<UnifiedSearchNothingFound
										viewModel={viewModel.nothingFoundViewModel}
									/>
								) : null}
							</StyledListItems>
						</>
					)}
				</StyledContent>
			</StyledOverlayWrapper>
		</StyledDialogOverlay>
	);
});

const StyledDialogOverlay = styled(DialogOverlay)`
	&[data-reach-dialog-overlay] {
		z-index: 2;
	}
`;

const StyledContent = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;
`;

const StyledSearchTitle = styled.h2`
	font-weight: 400;
	margin: 0 42px 0 0;
`;

const StyledListItems = styled.div`
	overflow: auto;
	display: flex;
	flex-direction: column;
	padding-bottom: 120px;
`;

const StyledOverlayWrapper = styled.div`
	display: flex;
	justify-content: center;
	padding-top: 33px;
	width: 100%;
	max-width: 100vw;
	height: calc(100vh - 80px);
	background-color: white;
	position: fixed;
	top: 80px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		top: 60px;
		height: calc(100vh - 60px);
	}
`;

const StyledTopWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 32px 0 42px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const StyledFiltersWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-top: 16px;
	}
`;

const StyledFilterButton = styled.button<{ active: boolean }>`
	cursor: pointer;
	border: 1px solid #ff5000;
	padding: 7px 16px;
	margin-right: 10px;
	border-radius: 4px;
	background-color: ${({ active }) => (active ? '#FF5000' : '#FFFFFF')};
	color: ${({ active }) => (active ? '#FFFFFF' : '#FF5000')};
	font-size: 15px;
	line-height: 26px;
	font-weight: 400;
`;

const StyledSearchInputButtonWrap = styled(SearchInputButtonWrap)`
	position: relative;
`;

const StyledClearInput = styled.div`
	position: absolute;
	right: 70px;
	top: 12px;
	cursor: pointer;
`;

const StyledEndOfResults = styled.div`
	width: 100%;
	text-align: center;
	margin: 20px 0;
`;

const StyledCancelIcon = styled(CancelIcon)`
	g {
		stroke: #585858;
	}
`;
