import React from 'react';
import { Global, css } from '@emotion/core';

import neueHaasUnicaW1GRegularTtf from './fonts/NeueHaasUnicaW1G-Regular.ttf';
import neueHaasUnicaW1GBoldTtf from './fonts/NeueHaasUnicaW1G-Bold.ttf';
import neueHaasUnicaW1GItalicTtf from './fonts/NeueHaasUnicaW1G-Italic.ttf';

import neueHaasUnicaW1GRegularWoff from './fonts/NeueHaasUnicaW1G-Regular.woff';
import neueHaasUnicaW1GBoldWoff from './fonts/NeueHaasUnicaW1G-Bold.woff';
import neueHaasUnicaW1GItalicWoff from './fonts/NeueHaasUnicaW1G-Italic.woff';

import neueHaasUnicaW1GRegularWoff2 from './fonts/NeueHaasUnicaW1G-Regular.woff2';
import neueHaasUnicaW1GBoldWoff2 from './fonts/NeueHaasUnicaW1G-Bold.woff2';
import neueHaasUnicaW1GItalicWoff2 from './fonts/NeueHaasUnicaW1G-Italic.woff2';

export const FONT_FAMILY_NEUE_HAAS_UNICA = 'NeueHaasUnicaW1G';

export const Fonts = () => {
	const styles = css`
		body {
			-webkit-font-smoothing: antialiased;
		}

		a {
			line-height: normal;
		}

		@font-face {
			font-family: ${FONT_FAMILY_NEUE_HAAS_UNICA};
			src: url('${neueHaasUnicaW1GRegularTtf}') format('truetype'),
				url('${neueHaasUnicaW1GRegularWoff2}') format('woff2'),
				url('${neueHaasUnicaW1GRegularWoff}') format('woff');
			font-display: swap;
			font-weight: normal;
		}

		@font-face {
			font-family: ${FONT_FAMILY_NEUE_HAAS_UNICA};
			src: url('${neueHaasUnicaW1GItalicTtf}') format('truetype'),
				url('${neueHaasUnicaW1GItalicWoff2}') format('woff2'),
				url('${neueHaasUnicaW1GItalicWoff}') format('woff');

			font-display: swap;
			font-weight: normal;
			font-style: italic;
		}

		@font-face {
			font-family: ${FONT_FAMILY_NEUE_HAAS_UNICA};
			src: url('${neueHaasUnicaW1GBoldTtf}') format('truetype'),
				url('${neueHaasUnicaW1GBoldWoff2}') format('woff2'),
				url('${neueHaasUnicaW1GBoldWoff}') format('woff');
			font-display: swap;
			font-weight: bold;
		}
	`;

	return <Global styles={styles} />;
};
