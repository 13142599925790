import { IPathToPageIdMap } from '@m2/blueprint-contentful-routes';
import { AutoSuggestFilterField } from '../../flights/search/domain/types/AutoSuggestFilterField';
import { getUrlForPage, Url } from '../../navigation';
import { IUnifiedSearchResult } from '../domain/models/IUnifiedSearchResult';
import { UnifiedSearchStore } from '../domain/stores/createUnifiedSearchStore';
import { PoiFilterField } from '../domain/types/PoiFilterField';
import { highlightText } from '../../utils/highlightText';
import { TypeFilterField } from '../domain/types/TypeFilterField';

export type SearchResultItemViewModel = ReturnType<
	typeof createSearchResultItemViewModel
>;

export interface ISearchResultItemViewModelProps {
	item: IUnifiedSearchResult;
	unifiedSearchStore: UnifiedSearchStore;
	pathToPageIdMap: IPathToPageIdMap;
}

export const createSearchResultItemViewModel = ({
	item,
	unifiedSearchStore,
	pathToPageIdMap,
}: ISearchResultItemViewModelProps) => {
	let contentType = TypeFilterField.Content;
	let url: Url | undefined;

	if (Object.values<string>(PoiFilterField).includes(item.filter)) {
		contentType = TypeFilterField.Pois;
	}

	if (Object.values<string>(AutoSuggestFilterField).includes(item.filter)) {
		contentType = TypeFilterField.Flights;
	}

	switch (item.filter) {
		case PoiFilterField.Pois:
			url = {
				pathname: '/map',
				query: { poiId: item.id },
			};
			break;
		case PoiFilterField.PoiTags:
		case PoiFilterField.PoiTerminals:
			const key = item.filter === PoiFilterField.PoiTags ? 'tag' : 'terminal';
			url = {
				pathname: '/shop-dine-relax',
				query: { [key]: item.title.toLowerCase() },
			};
			break;
		case AutoSuggestFilterField.MainFlightNumber:
			url = {
				pathname: 'flights',
				query: { mainFlightNumber: item.title },
			};
			break;
		case 'content':
			const path = getUrlForPage(pathToPageIdMap, item.id);
			url = path ?? undefined;
			break;
		default:
			const ignore = [
				PoiFilterField.Pois,
				PoiFilterField.PoiTags,
				PoiFilterField.PoiTerminals,
				AutoSuggestFilterField.MainFlightNumber,
				'content',
			];
			if (!ignore.includes(item.filter)) {
				const query = {
					[item.filter]: item.title,
				};
				url = { pathname: '/flights/', query };
			}
	}

	return {
		item: {
			...(contentType !== TypeFilterField.Content
				? {
						...item,
						title: highlightText(item.title, unifiedSearchStore?.userQuery),
						description: item.description
							? highlightText(item.description, unifiedSearchStore?.userQuery)
							: undefined,
				  }
				: item),
		},
		contentType,
		url,
		onClick() {
			unifiedSearchStore.setRecentSearchResult(item);
			unifiedSearchStore.setIsActive(false);
			unifiedSearchStore.reset();
		},
	};
};
