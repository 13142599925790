export enum Categories {
	All = 'All Categories',
	Shops = 'Shops',
	Dine = 'Dine',
	Relax = 'Relax',
}

export const Relax = 'relax';

export const Shops = 'shop';

export const Dine = 'eat';
