import { IAirport } from '../../../../../airports/types';
import { IFlight } from '../../../../shared/domain/types/IFlight';
import { AutoSuggestFilterField } from '../../types/AutoSuggestFilterField';
import { SuggestionGenerator } from '../../types/SuggestionGenerator';

export const generateAirportIataSuggestions: SuggestionGenerator = ({
	flights,
	airports,
	userInput,
}) =>
	airports
		.filter((airport: IAirport) =>
			airport.iata.toLocaleLowerCase().includes(userInput),
		)
		.filter((airport: IAirport) =>
			flights.find(
				(flight: IFlight) =>
					flight.destinationAirport === airport.iata ||
					flight.originAirport === airport.iata,
			),
		)
		.map((airport: IAirport) => ({
			filter: AutoSuggestFilterField.AirportIata,
			value: airport.iata,
			stringValue: airport.iata,
		}));
