import { IPoi } from '../../../shop-dine-relax/domain/types/IPoi';
import { IFlightSearchSuggestion } from '../../../flights/search/domain/types/IFlightSearchSuggestion';
import { TypeFilterField } from '../types/TypeFilterField';
import { IContentSearchResult } from '../../../content-search/domain/types/IContentSearchResult';

export function getFilteredResults(
	flights: IFlightSearchSuggestion[],
	pois: IPoi[],
	poiTags: string[],
	poiTerminals: string[],
	content: IContentSearchResult[],
	selectedFilters: TypeFilterField[],
) {
	if (!selectedFilters || selectedFilters.length === 0) {
		return {
			flights,
			pois,
			poiTags,
			poiTerminals,
			content,
		};
	}

	return {
		flights: selectedFilters.includes(TypeFilterField.Flights) ? flights : [],
		pois: selectedFilters.includes(TypeFilterField.Pois) ? pois : [],
		poiTags: selectedFilters.includes(TypeFilterField.Pois) ? poiTags : [],
		poiTerminals: selectedFilters.includes(TypeFilterField.Pois)
			? poiTerminals
			: [],
		content: selectedFilters.includes(TypeFilterField.Content) ? content : [],
	};
}
