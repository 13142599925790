import { makeAutoObservable } from 'mobx';
import { IAlgoliaSearchService } from '../../data/types/IAlgoliaSearchService';
import { IEntrySearchResultResponse } from '../../data/types/IEntrySearchResultResponse';

import { IContentSearchResult } from '../types/IContentSearchResult';

export type ContentSearchStore = ReturnType<typeof createContentSearchStore>;

interface IContentSearchStore {
	algoliaSearchService: IAlgoliaSearchService;
}

export function createContentSearchStore({
	algoliaSearchService,
}: IContentSearchStore) {
	return makeAutoObservable({
		userQuery: '',
		content: [] as IContentSearchResult[],
		reset() {
			this.setUserQuery('');
			this.setContent([]);
		},
		setUserQuery(userQuery: string) {
			this.userQuery = userQuery;

			if (this.userQuery) {
				this.fetchContentResults();
			}
		},
		async fetchContentResults() {
			const results = await algoliaSearchService.getSearchResults(
				this.userQuery,
			);
			const searchResults = results.map(mapResultToContentSearchResult);
			this.setContent(searchResults);
		},
		setContent(content: IContentSearchResult[]) {
			this.content = content;
		},
	});
}

function mapResultToContentSearchResult(
	result: IEntrySearchResultResponse,
): IContentSearchResult {
	return {
		pageId: result.pageId,
		slug: result.slug,
		id: result.objectID,
		// eslint-disable-next-line no-underscore-dangle
		snippet: result._snippetResult,
	};
}
