import { IPoi, getAllPois, ILocusMaps } from '@m2/blueprint-locuslabs-sdk';

export interface IGetAllPois {
	(): Promise<IPoi[]>;
}

export const createGetAllPois = async (locusMaps: ILocusMaps) => {
	const pois = await getAllPois(locusMaps);

	return pois;
};
