import { observer } from 'mobx-react';
import React from 'react';
import { useCommonData } from '../../common-data/useCommonData';
import { useRemoteConfig } from '../../config/useRemoteConfig';
import { usePoiStore } from '../../shop-dine-relax/domain/stores/usePoiStore';
import { createUnifiedSearchMenuViewModel } from './UnifiedSearchMenuViewModel';
import { UnifiedSearchViewModel } from './UnifiedSearchViewModel';
import { useTriggerOnRouteChange } from '../../utils/useTriggerOnRouteChange';
import { UnifiedSearchMenuToggle } from './UnifiedSearchMenuToggle';
import { SearchOverlay } from './SearchOverlay';

interface IUnifiedSearchProps {
	viewModel: UnifiedSearchViewModel;
}

export const UnifiedSearch = observer(({ viewModel }: IUnifiedSearchProps) => {
	const { value: isUnifiedSearchEnabled } = useRemoteConfig(
		'unified_search_enabled',
	);

	const { pathToPageIdMap } = useCommonData();

	const poiStore = usePoiStore();

	const handleRouteChange = () => {
		viewModel.unifiedSearchStore.setIsActive(false);
		viewModel.unifiedSearchStore.reset();
	};

	useTriggerOnRouteChange(handleRouteChange);

	if (!isUnifiedSearchEnabled) {
		return null;
	}

	const unifiedViewModel = createUnifiedSearchMenuViewModel({
		poiStore,
		isTransparent: viewModel.isTransparent,
		unifiedSearchStore: viewModel.unifiedSearchStore,
		pathToPageIdMap,
	});

	return (
		<>
			<UnifiedSearchMenuToggle
				onClick={viewModel.onClickToggle}
				viewModel={unifiedViewModel.menuToggleViewModel}
			/>
			<SearchOverlay viewModel={unifiedViewModel.overlayViewModel} />
		</>
	);
});
