import { IAirline } from '../../../../../airlines/types';
import { AutoSuggestFilterField } from '../../types/AutoSuggestFilterField';
import { SuggestionGenerator } from '../../types/SuggestionGenerator';

export const generateAirlineIataSuggestions: SuggestionGenerator = ({
	flights,
	userInput,
	airlines,
}) =>
	airlines
		.filter((airline: IAirline) =>
			airline.iata.toLocaleLowerCase().includes(userInput),
		)
		.filter((airline: IAirline) =>
			flights.find((flight) => flight.iataMainPrefix === airline.iata),
		)
		.map((airline: IAirline) => ({
			filter: AutoSuggestFilterField.AirlineIata,
			value: airline.iata,
			stringValue: airline.iata,
		}));
