import React from 'react';
import styled from '@emotion/styled';
import { v4 as uuidv4 } from 'uuid';
import { ParagraphWithCheck } from '../../../components/common/paragraph-with-check/ParagraphWithCheck';
import { Color } from '../../../styles/Color';
import { useTranslation } from '../../../i18n/translate/useTranslation';

interface ISignUpAccountBenefits {
	withoutHeader?: boolean;
	className?: string;
}

const SignUpAccountBenefits = ({
	withoutHeader = false,
	className,
}: ISignUpAccountBenefits) => {
	const { t } = useTranslation();
	const benefits = t('account_benefits_bullets');
	return (
		<div className={className}>
			{!withoutHeader && (
				<StyledAccountBenefitHeader>
					{t('account_benefits_title')}
				</StyledAccountBenefitHeader>
			)}
			{Array.isArray(benefits)
				? benefits.map((listItem) => (
						<ParagraphWithCheck key={uuidv4()}>{listItem}</ParagraphWithCheck>
				  ))
				: null}
		</div>
	);
};

export default SignUpAccountBenefits;

const StyledAccountBenefitHeader = styled.p`
	font-size: 16px;
	font-weight: 700;
	line-height: 21px;
	text-align: left;
	margin: 0 0 8px;
	color: ${Color.PrimaryDarkGray100};
`;
