import { useMemo } from 'react';
import { useCommonData } from '../../../common-data/useCommonData';
import { algoliaSearchService } from '../../../content-search/data/services';
import { createContentSearchStore } from '../../../content-search/domain/stores/createContentSearchStore';
import { useFlightStores } from '../../../flights/list/domain/stores/useFlightStores';
import { createFlightSuggestionStore } from '../../../flights/shared/domain/stores/createFlightSuggestionStore';

import { createUnifiedSearchStore } from './createUnifiedSearchStore';
import { usePoiStore } from '../../../shop-dine-relax/domain/stores/usePoiStore';
import { createPoiSearchStore } from '../../../shop-dine-relax/domain/stores/createPoiSearchStore';
import { persistRecentSearches } from '../use-cases/persistRecentSearches';

export function useUnifiedSearchStore() {
	const { airports, airlines } = useCommonData();

	const { flightStore, flightListStore } = useFlightStores();

	const poiStore = usePoiStore();

	return useMemo(() => {
		const contentSearchStore = createContentSearchStore({
			algoliaSearchService,
		});

		const poiSearchStore = createPoiSearchStore(poiStore);

		const flightSuggestionStore = createFlightSuggestionStore({
			airlines,
			airports,
			flightStore,
			flightListStore,
			minLength: 3,
		});

		const unifiedSearchStore = createUnifiedSearchStore({
			flightSuggestionStore,
			poiSearchStore,
			contentSearchStore,
			persistRecentSearches,
		});

		return unifiedSearchStore;
	}, []);
}
