import { AxiosRequestConfig } from 'axios';
import { IShuttleStopInfoResponse } from '../types/ISkydiveShuttleStopInfoResponse';
import { IShuttleStopsResponse } from '../types/ISkydiveShuttleStopsResponse';
import { AbstractService } from './AbstractService';

export class ShuttleStopsService extends AbstractService {
	/**
	 * Path of the resource to be used by the service.
	 */
	public static readonly URI_PATH = '/bus-stops';

	public getShuttleStops(
		requestConfig: AxiosRequestConfig = {
			method: 'GET',
		},
	): Promise<IShuttleStopsResponse> {
		const uri = ShuttleStopsService.URI_PATH;

		return this.client.request(uri, requestConfig);
	}

	public getShuttleStopInfo(
		id: string,
		requestConfig: AxiosRequestConfig = { method: 'GET' },
	): Promise<IShuttleStopInfoResponse> {
		const uri = `${ShuttleStopsService.URI_PATH}/${id}`;

		return this.client.request(uri, requestConfig);
	}
}
