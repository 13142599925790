import { makeAutoObservable } from 'mobx';
import { getFilteredPoiItems } from '../use-cases/getFilteredPoiItems';
import { Categories } from '../types/Categories';
import { AllTagsArray, AllTagsLabel } from '../types/Tags';
import { TerminalList, Terminals } from '../types/Terminals';

import { getOrderedPoiItems } from '../use-cases/getOrderedPoiItems';
import { PoiStore } from './createPoiStore';

export type PoiSearchStore = ReturnType<typeof createPoiSearchStore>;

const DEFAULT_INDEX = 20;

export const createPoiSearchStore = (
	poiStore: PoiStore,
	initialTag?: string,
	initialTerminal?: string,
) => {
	const store = makeAutoObservable({
		category: Categories.All as string,
		tag: initialTag ?? AllTagsLabel,
		terminal: initialTerminal ?? (Terminals.AllTerminals as string),
		userQuery: '',
		showUntilIndex: DEFAULT_INDEX,
		isPoiOpenStatusEnabled: false,
		setIsPoiOpenStatusEnabled(isEnabled: boolean) {
			this.isPoiOpenStatusEnabled = isEnabled;
		},
		clearSearchInput() {
			this.setUserQuery('');
		},
		setShowUntilIndex(newIndex: number) {
			this.showUntilIndex = newIndex;
		},
		resetIndex() {
			this.showUntilIndex = DEFAULT_INDEX;
		},
		setCategory(category: Categories) {
			this.resetIndex();
			this.category = category;
		},
		setTag(tag: string) {
			this.resetIndex();
			this.tag = tag;
		},
		setTerminal(terminal: string) {
			this.resetIndex();
			this.terminal = terminal;
		},
		setUserQuery(userQuery: string) {
			this.resetIndex();
			this.userQuery = userQuery;
		},
	});

	return {
		get pois() {
			if (!poiStore.value) {
				return [];
			}

			const filteredPois = getFilteredPoiItems(poiStore.value, {
				category: store.category,
				tag: store.tag,
				terminal: store.terminal,
				userQuery: store.userQuery,
			});

			return getOrderedPoiItems(filteredPois).slice(0, store.showUntilIndex);
		},
		get tags() {
			return Object.values(AllTagsArray).filter((tag) =>
				tag.toLowerCase().includes(store.userQuery.toLowerCase()),
			);
		},
		get terminals() {
			return TerminalList.filter((terminal) =>
				terminal.toLowerCase().includes(store.userQuery.toLowerCase()),
			);
		},
		setCategory: store.setCategory.bind(store),
		setTag: store.setTag.bind(store),
		setTerminal: store.setTerminal.bind(store),
		setUserQuery: store.setUserQuery.bind(store),
		clearSearchInput: store.clearSearchInput.bind(store),
		get isPoiOpenStatusEnabled() {
			return store.isPoiOpenStatusEnabled;
		},
		setIsPoiOpenStatusEnabled: store.setIsPoiOpenStatusEnabled.bind(store),
		get category() {
			return store.category;
		},
		get tag() {
			return store.tag;
		},
		get terminal() {
			return store.terminal;
		},
		get userQuery() {
			return store.userQuery;
		},
		get hasMore() {
			return poiStore.value
				? store.showUntilIndex < poiStore.value.length
				: false;
		},
		showMore() {
			store.setShowUntilIndex(store.showUntilIndex + 20);
		},
	};
};
