import { IAirport } from '../../../../../airports/types';
import { IFlight } from '../../../../shared/domain/types/IFlight';
import { AutoSuggestFilterField } from '../../types/AutoSuggestFilterField';
import { SuggestionGenerator } from '../../types/SuggestionGenerator';

export const generateAirportNameSuggestions: SuggestionGenerator = ({
	airports,
	flights,
	userInput,
}) => [
	...airports
		.filter((airport: IAirport) =>
			airport.airportName?.toLocaleLowerCase().includes(userInput),
		)
		.filter((airport: IAirport) =>
			flights.find(
				(flight: IFlight) =>
					flight.destinationAirport === airport.iata ||
					flight.originAirport === airport.iata,
			),
		)
		.map((airport: IAirport) => ({
			filter: AutoSuggestFilterField.AirportName,
			value: airport.airportName,
			stringValue: airport.airportName,
		})),
];
