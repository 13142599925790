import React from 'react';

import { NoResultsMessage } from '../../components/common/error';
import { UnifiedSearchEmptyStateViewModel } from './UnifiedSearchEmptyStateViewModel';

export interface IUnifiedSearchEmptyStateProps {
	viewModel: UnifiedSearchEmptyStateViewModel;
}

export const UnifiedSearchEmptyState = ({
	viewModel: { body, imageSource },
}: IUnifiedSearchEmptyStateProps) => (
	<NoResultsMessage
		title=""
		imageSource={imageSource}
		imageAlt={body}
		body={body}
	/>
);
