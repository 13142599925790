import { createContext, useContext } from 'react';
import { getAllPois } from '../use-cases';
import { createPoiStore } from './createPoiStore';
import { createPoiSearchStore } from './createPoiSearchStore';

const poiStore = createPoiStore(getAllPois);
const poiSearchStore = createPoiSearchStore(poiStore);

export const PoiStoreContext = createContext(poiStore);
export const PoiSearchStoreContext = createContext(poiSearchStore);

export const usePoiStore = () => useContext(PoiStoreContext);
export const usePoiSearchStore = () => useContext(PoiSearchStoreContext);
