import { IAirline } from '../../../../../airlines/types';
import { IFlight } from '../../../../shared/domain/types/IFlight';
import { AutoSuggestFilterField } from '../../types/AutoSuggestFilterField';
import { SuggestionGenerator } from '../../types/SuggestionGenerator';

export const generateAirlineNameSuggestions: SuggestionGenerator = ({
	airlines,
	flights,
	userInput,
}) =>
	airlines
		.filter((airline: IAirline) =>
			airline.airlineName?.toLocaleLowerCase().includes(userInput),
		)
		.filter((airline: IAirline) =>
			flights.find((flight: IFlight) => flight.icaoMainPrefix === airline.icao),
		)
		.map((airline: IAirline) => ({
			filter: AutoSuggestFilterField.AirlineName,
			value: airline.airlineName,
			stringValue: airline.airlineName,
		}));
