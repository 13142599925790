import { Shops, Dine, Relax, Categories } from '../types/Categories';
import { IPoi } from '../types/IPoi';

export function itemsCategoryFilter(category: string, poi: IPoi) {
	if (category === Categories.All) {
		return [Shops, Dine, Relax].some((poiCategory) =>
			isCategoryMatch(poiCategory, poi),
		);
	}

	if (category === Categories.Shops) {
		return isCategoryMatch(Shops, poi);
	}
	if (category === Categories.Dine) {
		return isCategoryMatch(Dine, poi);
	}
	if (category === Categories.Relax) {
		return isCategoryMatch(Relax, poi);
	}
	return false;
}

const isCategoryMatch = (category: string, poi: IPoi) =>
	poi.category === category || isSubcategoryMatch(category, poi);

const isSubcategoryMatch = (category: string, poi: IPoi) =>
	poi.category.startsWith(`${category}.`);
